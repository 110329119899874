
import Vue from 'vue';
import AtomButton, { ButtonSize, ButtonVariant } from '@/components/atoms/AtomButton.vue';

export interface SelectButtonChangeEvent {
  id: string;
  isSelected: boolean;
}

export default Vue.extend({
  name: 'AtomSelectButton',
  components: { AtomButton },
  props: {
    id: {
      type: String,
      required: false,
    },
    content: {
      type: String,
      required: true,
    },
    subContent: {
      type: String,
      required: false,
    },
    prefix: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ButtonSize,
      ButtonVariant,
      isSelected: this.selected,
    };
  },
  watch: {
    selected: 'changeSelectedState',
  },
  methods: {
    changeSelectedState(isSelected: boolean) {
      this.isSelected = isSelected;
    },
    toggleSelection() {
      this.$emit('change', { id: this.id, isSelected: !this.isSelected } as SelectButtonChangeEvent);
    },
  },
});
