
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

import AtomButton, { ButtonVariant, ButtonSize } from '@/components/atoms/AtomButton.vue';
import AtomSetup from '@/components/atoms/AtomSetup.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomSelectButton from '@/components/atoms/AtomSelectButton.vue';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';
import MoleculeModal from '@/components/molecules/modal/MoleculeModal.vue';
import OrganismLanguageSelectorModal, {
  LanguageSelector,
} from '@/components/organisms/language/OrganismLanguageSelectorModal.vue';
import OrganismPageSubHeader from '@/components/organisms/page-header/OrganismPageSubHeader.vue';

import { updateUser } from '@/api/user/userApi';

export default Vue.extend({
  name: 'TemplateSettingsRegion',
  components: {
    AtomButton,
    AtomSetup,
    AtomText,
    AtomSelectButton,
    MoleculeModal,
    OrganismLanguageSelectorModal,
    OrganismPageSubHeader,
  },
  data() {
    return {
      ButtonVariant,
      ButtonSize,
      userDateLocale: '',
      userLocale: {} as LanguageSelector,
      userTimeLocale: '',
      userUnitOfLengthLocale: '',
      dateTimeFormatList: [
        {
          dateLocale: moment().locale(this.$i18n.locale).format('dddd, D MMM hh:mm A'), // dddd, MMMM Do YYYY, h:mm:ss a
          content: this.$t('settings.en_GB'),
          code: 'en_UK',
        },
        {
          dateLocale: moment().locale(this.$i18n.locale).format('dddd, MMM D hh:mm A'),
          content: this.$t('settings.en_US'),
          code: 'en_US',
        },
        {
          dateLocale: moment().locale(this.$i18n.locale).format('dddd, D. MMM hh:mm'),
          content: this.$t('settings.de_DE'),
          code: 'de',
        },
      ],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('user', [
      'user',
      'getUserId',
      'firstName',
      'lastName',
      'displayName',
      'phoneNumber',
      'rideSettings',
      'sms',
      'locale',
      'dateLocale',
      'timeLocale',
      'unitOfLengthLocale',
    ]),
    getTimeFormatBtnLabel(): string {
      const dateFormat = this.dateTimeFormatList.find((f) => f.code === this.userTimeLocale);
      return dateFormat
        ? dateFormat.dateLocale
        : this.$t('settings.dateTimeFormatButton').toString();
    },
    isSaveBtnDisabled(): boolean {
      return !(
        this.locale !== this.userLocale.locale ||
        this.userUnitOfLengthLocale !== this.unitOfLengthLocale ||
        this.userTimeLocale !== this.timeLocale
      );
    },
  },
  methods: {
    ...mapActions('user', ['fetchUser']),
    setUserData() {
      this.userUnitOfLengthLocale = this.unitOfLengthLocale;
      this.userTimeLocale = this.timeLocale;
      this.userDateLocale = this.dateLocale;
      this.userLocale = {
        locale: this.locale,
        englishName: this.$t(`dictionary.locales.${this.locale.replace('-', '_')}`).toString(),
        nameKey: `dictionary.locales.${this.locale.replace('-', '_')}`,
      };
    },
    async finish() {
      this.isLoading = true;
      const { status } = await updateUser({
        userId: this.getUserId,
        firstName: this.firstName,
        lastName: this.lastName,
        displayName: this.displayName,
        rideSettings: this.rideSettings,
        sms: {
          detail: this.phoneNumber,
          usageTypes: this.sms.usageTypes,
        },
        locale: this.userLocale.locale.replace('-', '_'),
        unitOfLengthLocale: this.userUnitOfLengthLocale,
        timeLocale: this.userTimeLocale,
        dateLocale: this.userTimeLocale,
      });
      this.isLoading = false;
      if (status === 200) {
        await this.fetchUser(this.getUserId);
        toast(this.$bvToast, this.$t('settings.rides.messages.success').toString());
      } else {
        toast(
          this.$bvToast,
          this.$t('settings.rides.messages.error').toString(),
          ToastVariants.DANGER,
        );
      }
    },
  },
  mounted() {
    this.setUserData();
  },
  watch: {
    user(_, prevUser) {
      if (prevUser === null) {
        this.setUserData();
      }
    },
  },
});
